import {
  AlphaCard,
  Button,
  FormLayout,
  Page,
  Text,
  Toast,
  VerticalStack,
} from "@shopify/polaris";
import React, { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config.json";
const ExtensionGenerate = () => {
 const navigate = useNavigate();
  const { myShopifyDomain } = useParams();

  //success Toast
  const [successTost, setSuccessTost] = useState(false);
  const [successTostContent, setSuccessTostContent] = useState("");
  const toggleSuccessActive = useCallback(
    () => setSuccessTost((successTost) => !successTost),
    []
  );
  const successToastMarkup = successTost ? (
    <Toast content={successTostContent} onDismiss={toggleSuccessActive} />
  ) : null;
  //error Toast
  const [errorTost, setErrorTost] = useState(false);
  const toggleErrorTost = useCallback(
    () => setErrorTost((errorTost) => !errorTost),
    []
  );
  const [errorContent, setErrorContent] = useState("Saving error");
  const errorTostMarkup = errorTost ? (
    <Toast content={errorContent} error onDismiss={toggleErrorTost} />
  ) : null;

  const createFunctions = async (val) => {
    let data = {
      Shop: myShopifyDomain,
      discountType: val,
    };
    await fetch(`${config.APIURL}/api/createAdditionalFunction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        let result = await response.json();
        toggleSuccessActive();
        setSuccessTostContent(result.message);
      })
      .catch((err) => {
        setErrorContent("Something went wrong");
        toggleErrorTost();
      });
  };

  return (
      <Page
      fullWidth
      backAction={{content: 'Products',onAction: () => { navigate("/DetailsPage") }}}
      title={`Store Name:-  ${myShopifyDomain ?? "shopify Domain"}`}
      compactTitle
            >
      <AlphaCard>
        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Automatic PRODUCT Function (DRAFT/ Shop Pay /
              OTHER){" "}
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("productOtherFunction")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>

        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Automatic ORDER Function (DRAFT/ Shop Pay / OTHER){" "}
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("orderOtherFunction")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>

        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Automatic PRODUCT Function (POS) New Customer 
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("posFunction")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>

        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Automatic ORDER Function (POS) New Customer
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("orderposFunction")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>

        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Automatic PRODUCT Function (POS) Old
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("oldPosFunction")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>

        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Automatic ORDER Function (POS) Old
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("oldOrderposFunction")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>

        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Cart Attribute Function (PRODUCT)
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("productCartAttributeFun")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>


        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Cart Attribute Function (ORDER)
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("orderCartAttributeFun")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>
        <FormLayout.Group>
          <VerticalStack gap="3">
            <Text fontWeight="bold" alignment="center">
              Click To Create Checkout Delivery Customization
            </Text>
            <Button
              size="large"
              primary
              onClick={() => createFunctions("checkoutDelivery")}
            >
              Click
            </Button>
          </VerticalStack>
        </FormLayout.Group>
      </AlphaCard>
      {successToastMarkup}
      {errorTostMarkup}
      </Page>
  );
};

export default ExtensionGenerate;
